import { useAppSelector } from "app/hooks";
import { StyledContainer } from "app/theme";
import FormGenerator from "components/FormGenerator";
import CustomStepper from "components/Stepper";
import { Row, Col, Spinner } from "react-bootstrap";

import KioskBoard from "kioskboard";
import { useEffect } from "react";

function Home() {
  const formConfig = useAppSelector((state) => state.config.form);
  const uiConfig = useAppSelector((state) => state.config.ui);
  const virtualKeyboardsConfig = useAppSelector(
    (state) => state.config.virtualKeyboards
  );

  useEffect(() => {
    if (Object.entries(virtualKeyboardsConfig).length > 0) {
      for (const [, virtualKeyboard] of Object.entries(
        virtualKeyboardsConfig
      )) {
        KioskBoard.run(virtualKeyboard.target, virtualKeyboard.options);
      }
    }
  }, [virtualKeyboardsConfig]);

  return (
    <>
      <StyledContainer
        fluid
        style={{
          height: "fit-content",
        }}
        className="px-3"
      >
        <Row
          className="px-3"
        >
          {formConfig.groups ? (
            <>
              <CustomStepper formConfig={formConfig} />
            </>
          ) : (
            <>
              <StyledContainer fluid className="text-center">
                <Spinner animation="grow" /> Loading...
              </StyledContainer>
            </>
          )}
        </Row>
        <Row
          className="p-4 text-center"
        >
          <Col>
            <Row>
              {formConfig.groups ? (
                <>
                  <FormGenerator formConfig={formConfig} uiConfig={uiConfig} />
                </>
              ) : null}
            </Row>
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
}

export default Home;

import { StyledContainer, StyledFloatingLabel } from "app/theme";
import { Form as FormType } from "features/config/type";
import InputField from "./InputField";
import { Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { generateQrCodeContent } from "actions/qrcode";
import Ticket from "components/Ticket";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { stepUpdated } from "features/stepper/stepper-slice";
import BottomNavbar from "components/BottomNavBar";
import ResumeModal from "components/ResumeModal";
import styled from "styled-components";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FiCheckSquare } from "react-icons/fi";
import html2canvas from "html2canvas";

const FormInputsContainer = styled(StyledContainer)`
  @media screen and (min-width: 1280px) {
    width: 640px;
  }
  @media screen and (max-width: 1280px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;

const StyledTicketRow = styled(Row)`
  z-index: -1;
  @media screen and (max-width: 576px) {
    position: fixed;
    left: -10000px;
  }
  @media screen {
    display: none;
  }
`;
function FormGenerator({
  formConfig,
  uiConfig,
}: {
  formConfig: FormType;
  uiConfig: any;
}) {
  const dispatch = useAppDispatch();
  const { handleSubmit, register, setValue, reset, getValues, resetField } =
    useForm();
  let ticketRef = useRef<any>();
  const formRef = useRef<HTMLFormElement>(null);
  const currentStep = useAppSelector((state) => state.stepper.currentStep);
  const maxStep = useAppSelector((state) => state.stepper.maxStep);
  const qrCodeContentConfig = useAppSelector(
    (state) => state.qrcode.qrContentConfig
  );
  const qrCodeContent = useAppSelector((state) => state.qrcode.qrContent);
  // get encoding from url ?encoding=xxx
  const urlParams = new URLSearchParams(window.location.search);
  const encoding = urlParams.get("encoding");

  const [nextPerson, setNextPerson] = useState(false);
  const [readyToPrintOrDownload, setReadyToPrintOrDownload] = useState(false);

  // This useEffect will handle the printing once everything is ready
  useEffect(() => {
    if (readyToPrintOrDownload) {
      if (nextPerson) {
        if (window.innerWidth < 576) {
          handleDownloadNextPerson();
        } else {
          handleNextPerson();
        }
      } else {
        if (window.innerWidth < 576) {
          handleDownload();
        } else {
          handlePrint();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToPrintOrDownload, nextPerson]);

  const dispatchAndPrint = (data: any) => {
    dispatch(
      generateQrCodeContent(
        {
          form: { ...data, context: formConfig.context },
          encoding: encoding?.toLocaleLowerCase(),
        },
        qrCodeContentConfig,
        encoding?.toLocaleLowerCase(),
        true
      )
    );
    setReadyToPrintOrDownload(true);
  };

  const handleChanges = () => {
    forceFormFieldsUpdate();
    dispatch(
      generateQrCodeContent(
        {
          form: { ...getValues(), context: formConfig.context },
          encoding: encoding?.toLocaleLowerCase(),
        },
        qrCodeContentConfig,
        encoding?.toLocaleLowerCase(),
        false
      )
    );
  };

  const handlePrint = useReactToPrint({
    content: () => ticketRef.current,
    onAfterPrint: () => {
      reset();
      window.location.reload();
    },
  });

  const downloadTicket = async () => {
    if (!ticketRef.current) return;
    ticketRef.current.style.display = "block";
    const canvas = await html2canvas(ticketRef.current);
    const imgURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = imgURL;
    // Link download name by getting the value from the qrCodeContent and set it only if in the formConfig the field has a property downloadTitle : true
    link.download = formConfig.groups
      .map((group) => {
        return group.inputs
          .map((input) => {
            if (input.downloadTitle) {
              return qrCodeContent.payload?.form[input.id];
            }
            return null;
          })
          .filter((input) => input !== null)
          .join(" ");
      })
      .join("");
    link.click();
  };

  const nextPersonReset = () => {
    formConfig.groups.forEach((group) => {
      group.inputs.forEach((input) => {
        if (input.resettable)
          resetField(input.id, {
            defaultValue: input.defaultValue,
          });
      });
    });
    dispatch(stepUpdated(0));
    setReadyToPrintOrDownload(false);
  };

  const handleDownload = async () => {
    await downloadTicket();
    // refresh the page
    window.location.reload();
  };

  const handleDownloadNextPerson = async () => {
    await downloadTicket();
    ticketRef.current.style.display = "none";
    nextPersonReset();
  };

  const handleNextPerson = useReactToPrint({
    content: () => ticketRef.current,
    onAfterPrint: () => {
      nextPersonReset();
    },
  });

  // There is a bug with the react-hook-form library and the bootstrap datepicker, which doesn't update the form values when the date is changed
  const forceFormFieldsUpdate = () => {
    // For each group in the form (from the config)
    formConfig.groups.forEach((group) => {
      // For each input in the group
      group.inputs.forEach((input) => {
        // Update the value of the input in the form
        setValue(
          input.id,
          (
            document.querySelector(`#${input.id}`) as
              | HTMLInputElement
              | HTMLSelectElement
          )?.value
        );
      });
    });
  };

  // useEffect which detect the current step, and if an input in that step hasn't been filled, the step will revert to the previous one and the input will be highlighted
  useEffect(() => {
    forceFormFieldsUpdate();

    // Iterate over all previous steps
    for (let step = currentStep - 1; step >= 0; step--) {
      const currentGroup = formConfig.groups.find(
        (group) => group.step === step
      );

      // If the group is found
      if (currentGroup) {
        // Check if all the inputs in the group are filled
        const hasMissingInputs = currentGroup.inputs.some((input) => {
          // If the input is not required, it is considered filled
          if (!input.required) return false;

          // Check if the input is filled
          const inputValue = getValues(`${input.id}`);
          return (
            inputValue === undefined || inputValue === "" || inputValue === null
          );
        });

        if (hasMissingInputs) {
          dispatch(stepUpdated(step));
          // Add an "is-invalid" class to all missing inputs
          currentGroup.inputs.forEach((input) => {
            if (input.required && !getValues(`${input.id}`)) {
              document
                .querySelector(`#${input.id}`)
                ?.classList.add("is-invalid");
            }
          });
          // Break the loop as we have found the earliest step with missing inputs
          break;
        } else {
          // Remove the "is-invalid" class from all inputs
          currentGroup.inputs.forEach((input) => {
            document
              .querySelector(`#${input.id}`)
              ?.classList.remove("is-invalid");
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <>
      <BottomNavbar forceUpdate={handleChanges} resetForm={reset} />
      <Form
        onSubmit={handleSubmit(dispatchAndPrint, (e) => {
          console.error(e);
        })}
        ref={formRef}
        style={{
          margin: 0,
          marginTop: 0,
        }}
        id={formConfig.id}
      >
        {formConfig.groups.map((group) => (
          <FormInputsContainer
            fluid
            key={group.name}
            style={{
              display: group.step === currentStep ? "block" : "none",
            }}
          >
            {group.inputs.map((input) => (
              <StyledFloatingLabel
                label={input.label}
                className={["mb-3", input.div_classes].join(" ")}
                key={input.id}
              >
                <InputField input={input} register={register} />
              </StyledFloatingLabel>
            ))}
          </FormInputsContainer>
        ))}
        <FormInputsContainer
          fluid
          style={{
            display: currentStep === maxStep ? "block" : "none",
          }}
          className="p-4 text-center"
        >
          <Row>
            <h2>{uiConfig.messages.thanks}</h2>
          </Row>
          <Row className="mt-3">
            <ResumeModal
              title="Check before printing"
              buttonContent={
                <>
                  <FiCheckSquare /> {uiConfig.buttons.print}
                </>
              }
              buttonColor="green"
              forceUpdate={handleChanges}
              handlePrint={() => {
                setNextPerson(false);
                forceFormFieldsUpdate();
                // Trigger Submit event
                formRef.current?.dispatchEvent(
                  new Event("submit", { cancelable: true, bubbles: true })
                );
              }}
            />
          </Row>
          <Row className="mt-2">
            <ResumeModal
              title="Check before printing"
              buttonContent={
                <>
                  <BsFillPersonPlusFill /> {uiConfig.buttons.user}
                </>
              }
              buttonColor="blue"
              forceUpdate={handleChanges}
              handlePrint={() => {
                setNextPerson(true);
                forceFormFieldsUpdate();
                // Trigger Submit event
                formRef.current?.dispatchEvent(
                  new Event("submit", { cancelable: true, bubbles: true })
                );
              }}
            />
          </Row>
          <StyledTicketRow ref={ticketRef}>
            <Ticket />
          </StyledTicketRow>
        </FormInputsContainer>
      </Form>
    </>
  );
}

export default FormGenerator;

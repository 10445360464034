import { useAppDispatch, useAppSelector } from "app/hooks";
import { BlueButton, RedButton, StyledNavbar } from "app/theme";
import ResumeModal from "components/ResumeModal";
import {
  previousStepUpdated,
  nextStepUpdated,
} from "features/stepper/stepper-slice";
import { MdOutlineCancel } from "react-icons/md";
// import { FiRefreshCcw } from "react-icons/fi";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
// import { AiOutlineOrderedList } from "react-icons/ai";
import { GoChecklist } from "react-icons/go";
// import { useLayoutEffect, useState } from "react";

function BottomNavbar(_props: { forceUpdate: () => void, resetForm: () => void}) {
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector((state) => state.stepper.currentStep);
  const maxStep = useAppSelector((state) => state.stepper.maxStep);
  //const [isMobile, setIsMobile] = useState(false);

  // useLayoutEffect(() => {
  //   function checkSize() {
  //     setIsMobile(window.innerWidth <= 768);
  //   }
  //   window.addEventListener('resize', checkSize);
  //   checkSize();
  //   return () => window.removeEventListener('resize', checkSize);
  // }, []);

  return (
    <>
      <StyledNavbar
        className={`d-flex justify-content-between`}
        style={
          {
            border: "none",
            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
            marginBottom: "6rem",
          }
        }
      >
        <div>
          {/* <RedButton
            onClick={() => {
              if (
                window.confirm("Are you sure you want to reset the form? \n")
              ) {
                _props.resetForm();
              }
            }}
          >
            <FiRefreshCcw />
            <MdOutlineCancel />
          </RedButton> */}
          <RedButton
            className="mx-2"
            onClick={() => {
              // Ask for confirmation
              if (
                window.confirm(
                  "Are you sure you want to reset the form? \n"
                )
              )
                window.location.reload();
            }}
          >
            <MdOutlineCancel size={30}/>
          </RedButton>
          <ResumeModal forceUpdate={_props.forceUpdate} title="Recap" buttonContent={<GoChecklist size={30}/>} buttonColor="blue"/>
        </div>
        <div>
          
        </div>
        <div>
          <BlueButton
            className="mx-2"
            onClick={() => dispatch(previousStepUpdated())}
            disabled={currentStep <= 0}
          >
            <MdOutlineNavigateBefore size={30} />
          </BlueButton>
          <BlueButton
            onClick={() => dispatch(nextStepUpdated())}
            disabled={currentStep >= maxStep}
          >
            <MdOutlineNavigateNext size={30} />
          </BlueButton>
        </div>
      </StyledNavbar>
    </>
  );
}

export default BottomNavbar;

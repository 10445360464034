// DUCKS pattern

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QrCodeContent } from "./type";

const initialState = {
  qrConfig: {} as any,
  qrContentConfig: {} as any,
  encodedQrContent: "",
  rawQrContent: "",
  qrContent: {} as QrCodeContent,
  forceUpdate: false,
};

export const qrcodeSlice = createSlice({
  name: "qrcode",
  initialState,
  reducers: {
    qrContentUpdated: (state, action: PayloadAction<QrCodeContent>) => {
      state.qrContent = action.payload;
    },
    encodedQrContentUpdated: (state, action: PayloadAction<string>) => {
      state.encodedQrContent = action.payload;
    },
    qrConfigUpdated: (state, action: PayloadAction<any>) => {
      state.qrConfig = action.payload;
    },
    qrContentConfigUpdated: (state, action: PayloadAction<any>) => {
      state.qrContentConfig = action.payload;
    },
    rawQrContentUpdated: (state, action: PayloadAction<string>) => {
      state.rawQrContent = action.payload;
    },
    forceUpdateStateUpdated: (state, action: PayloadAction<boolean>) => {
      state.forceUpdate = action.payload;
    }
  },
});

// Export the actions
export const {
  qrContentUpdated,
  encodedQrContentUpdated,
  qrConfigUpdated,
  qrContentConfigUpdated,
  rawQrContentUpdated,
  forceUpdateStateUpdated
} = qrcodeSlice.actions;

export default qrcodeSlice.reducer;

import {
  Step,
  StepConnector,
  StepIconProps,
  Stepper,
  stepConnectorClasses,
} from "@mui/material";
import { StyledStepLabel } from "app/theme";
import styled from "styled-components";
import { Check } from "@mui/icons-material";
import { Form } from "features/config/type";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect } from "react";
import { stepUpdated } from "features/stepper/stepper-slice";
import { AiFillPrinter } from "react-icons/ai";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.colors.green,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.colors.green,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.colors.overlay2,
    transition: "ease-in-out 0.2s",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.colors.text,
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: theme.colors.blue,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: theme.colors.green,
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function CustomStepper(_props: { formConfig: Form }) {
  const dispatch = useAppDispatch();
  const formConfig = useAppSelector((state) => state.config.form);
  const currentStep = useAppSelector((state) => state.stepper.currentStep);
  const maxStep = useAppSelector((state) => state.stepper.maxStep);

  useEffect(() => {
    dispatch(stepUpdated(formConfig.groups.length > 0 ? 0 : -1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stepper activeStep={currentStep} connector={<QontoConnector />}>
      {_props.formConfig.groups.map((group, index) => (
        <Step
          key={`step-${index}`}
          onClick={() => dispatch(stepUpdated(index))}
        >
          <StyledStepLabel StepIconComponent={QontoStepIcon}>
            <p style={{ margin: 0, padding: 0, fontSize: "18px" }}>
              {group.name}
            </p>
          </StyledStepLabel>
        </Step>
      ))}
      <Step onClick={() => dispatch(stepUpdated(maxStep))}>
        <StyledStepLabel StepIconComponent={QontoStepIcon}>
          <AiFillPrinter size={18}></AiFillPrinter>
        </StyledStepLabel>
      </Step>
    </Stepper>
  );
}

export default CustomStepper;

// DUCKS pattern

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Form, VirtualKeyboards } from "./type";

const initialState = {
  form: {} as Form,
  ui: {} as any,
  virtualKeyboards: {} as VirtualKeyboards,
  themes: {} as any,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    formConfigUpdated: (state, action: PayloadAction<any>) => {
      state.form = action.payload;
    },
    uiConfigUpdated: (state, action: PayloadAction<any>) => {
      state.ui = action.payload;
    },
    virtualKeyboardsConfigUpdated: (state, action: PayloadAction<any>) => {
      state.virtualKeyboards = action.payload;
    },
    themesConfigUpdated: (state, action: PayloadAction<any>) => {
      state.themes = action.payload;
    }
  },
});

// Export the actions
export const { formConfigUpdated, uiConfigUpdated, virtualKeyboardsConfigUpdated, themesConfigUpdated } = configSlice.actions;

export default configSlice.reducer;

// DUCKS pattern

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    error: "",
};

export const errorSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    errorUpdated(state, action: PayloadAction<string>) {
        state.error = action.payload;
    }
  },
});

// Export the actions
export const { errorUpdated } = errorSlice.actions;

export default errorSlice.reducer;

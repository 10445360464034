import { useAppDispatch, useAppSelector } from "app/hooks";
import { errorUpdated } from "features/err/err-slice";
import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledAlert = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.red};
  z-index: 999;
  transition: all 0.5s ease-in-out;
  color: ${(props) => props.theme.colors.base};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slide-in 0.3s ease-in-out;

  @keyframes slide-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: ${(props) => props.theme.colors.base};
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
`;

function Alert() {
  const dispatch = useAppDispatch();
  const [display, setDisplay] = useState(false);

  const error = useAppSelector((state) => state.error.error);

  useEffect(() => {
    // Loop through all errors, if any of them are true, set display to true
    if (error) {
      setDisplay(true);
      setTimeout(() => {
        setDisplay(false);
        dispatch(errorUpdated(""));
      }, 5000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      {display ? (
        <StyledAlert>
          <b>{error}</b>
          <CloseButton onClick={() => setDisplay(false)}>✗</CloseButton>
        </StyledAlert>
      ) : null}
    </>
  );
}

export default Alert;

import { firstLetterToUpperCase } from "app/common";
import { useAppSelector } from "app/hooks";
import { BlueButton, GreenButton, RedButton, StyledModal } from "app/theme";
import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";

function ResumeModal(_props: {
  title: string;
  buttonContent: any;
  buttonColor: "red" | "green" | "blue";
  forceUpdate: () => void;
  handlePrint?: () => void;
}) {
  const [show, setShow] = useState(false);
  const qrCodeContent = useAppSelector((state) => state.qrcode.qrContent);
  const formConfig = useAppSelector((state) => state.config.form);
  const ButtonSelector = (color: string) => {
    switch (color) {
      case "red":
        return (
          <RedButton
            onClick={() => {
              _props.forceUpdate();
              setShow(true);
            }}
          >
            {_props.buttonContent}
          </RedButton>
        );
      case "green":
        return (
          <GreenButton
            onClick={() => {
              _props.forceUpdate();
              setShow(true);
            }}
          >
            {_props.buttonContent}
          </GreenButton>
        );
      case "blue":
        return (
          <BlueButton
            onClick={() => {
              _props.forceUpdate();
              setShow(true);
            }}
          >
            {_props.buttonContent}
          </BlueButton>
        );
      default:
        return (
          <BlueButton
            onClick={() => {
              _props.forceUpdate();
              setShow(true);
            }}
          >
            {_props.buttonContent}
          </BlueButton>
        );
    }
  };

  return (
    <>
      {ButtonSelector(_props.buttonColor)}
      <StyledModal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <StyledModal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Recap</Modal.Title>
        </StyledModal.Header>
        <StyledModal.Body>
        {formConfig.groups.map((group) => {
          return group.inputs.map((input) => {
            return (
              <React.Fragment key={input.id}>
                {qrCodeContent.payload
                  ? Object.entries(qrCodeContent.payload.form).map(
                      ([key, value]) => {
                        // If the input is not from this group, then return null.
                        if (!input.id.includes(key)) return null;
                        if (key === "context") return null;
                        if (key === input.id) {
                          return (
                            <p key={key}>
                              <b>{firstLetterToUpperCase(input.label)}:</b> {value}
                            </p>
                          );
                        }
                        return null;
                      }
                    )
                  : null}
              </React.Fragment>
            );
          });
        })}
        </StyledModal.Body>
        <StyledModal.Footer>
          {_props.handlePrint ? (
            <>
              <RedButton onClick={() => setShow(false)} className="mx-2">
                Close
              </RedButton>
              <GreenButton
                onClick={() => {
                  setShow(false);
                  // @ts-ignore
                  _props.handlePrint();
                }}
              >
                Print
              </GreenButton>
            </>
          ) : (
            <RedButton onClick={() => setShow(false)}>Close</RedButton>
          )}
        </StyledModal.Footer>
      </StyledModal>
    </>
  );
}

export default ResumeModal;

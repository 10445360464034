import { StyledFormControl, StyledFormSelect } from "app/theme";
import { Input } from "features/config/type";
import { FieldValues, UseFormRegister } from "react-hook-form";
import $ from "jquery";
import "bootstrap-datepicker";
import { useEffect } from "react";

const InputField = ({
  input,
  register,
}: {
  input: Input;
  register: UseFormRegister<FieldValues>;
}) => {
  const commonProps = {
    id: input.id,
    style: input.style,
    className: input.className,
    required: input.required,
  };
  let additionalProps: {
    type?: string;
    placeholder?: string;
    defaultValue?: string;
    disabled: boolean;
    min?: number;
    max?: number;
    "data-kioskboard-type"?: string;
    "data-kioskboard-placement"?: string;
    "data-kioskboard-specialcharacters"?: string;
    [key: string]: any;
  } = {
    disabled: input.disabled,
    "data-kioskboard-type": input["data-kioskboard-type"],
    "data-kioskboard-placement": input["data-kioskboard-placement"],
    "data-kioskboard-specialcharacters":
      input["data-kioskboard-specialcharacters"],
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.target.blur();
    }
  };

  // Initialize datepicker on mount otherwise it won't work
  useEffect(() => {
    // If type is Date, then initialize the datepicker
    if (input.type === "date") {
      $(`#${input.id}`).datepicker(input["date-picker-options"]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (input.type === "select") {
    return (
      <StyledFormSelect
        {...commonProps}
        {...register(input.id, { required: input.required })}
        {...additionalProps}
      >
        {input.options?.map((option, index) => (
          <option key={`option-${option.value}-${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledFormSelect>
    );
  }

  switch (input.type) {
    case "number":
      additionalProps = {
        ...additionalProps,
        type: input.type,
        placeholder: input.placeholder,
        defaultValue: input.defaultValue,
        min: input.min,
        max: input.max,
      };
      break;
    case "date":
      additionalProps = {
        ...additionalProps,
        type: "text",
        placeholder: input.placeholder,
        defaultValue: input.defaultValue,
        onChange: () => {
          console.log("change");
        },
      };
      break;
    default:
      additionalProps = {
        ...additionalProps,
        type: input.type,
        placeholder: input.placeholder,
        defaultValue: input.defaultValue,
      };
      break;
  }

  return (
    <StyledFormControl
      {...commonProps}
      key={input.id}
      {...additionalProps}
      {...register(input.id, { required: input.required })}
      onKeyDown={handleKeyDown}
    />
  );
};

export default InputField;

// import { useAppSelector } from "app/hooks";
import { useAppSelector } from "app/hooks";
import { StyledContainer, StyledNavbar } from "app/theme";

function NavBar({theme} : {theme: string}) {
  const uiConfig = useAppSelector((state) => state.config.ui);
  return (
    <>
      <StyledNavbar>
        <StyledContainer>
          <StyledNavbar.Brand>
            <img
              alt=""
              src={
                uiConfig.company && theme === "dark"
                  ? uiConfig.company.companyLogoDark
                  : uiConfig.company.companyLogo
              }
              height="90"
              className="d-inline-block align-top text-center"
            />
            {uiConfig.company ? ` ${uiConfig.company.companyName}` : " EKAMI"}
          </StyledNavbar.Brand>
          <StyledNavbar.Collapse className="justify-content-end">
        </StyledNavbar.Collapse>
        </StyledContainer>
      </StyledNavbar>
    </>
  );
}

export default NavBar;

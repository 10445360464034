// DUCKS pattern

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    currentStep: 0,
    maxStep: 0,
};

const checkStep = (state: any, step: number) => {
    let newStep = step;
    if (step < 0) {
        newStep = 0;
    }
    if (step > state.maxStep) {
        newStep = state.maxStep;
    }
    return newStep;
}

export const stepperSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    nextStepUpdated: (state) => {
        state.currentStep = checkStep(state, state.currentStep + 1);
    },
    previousStepUpdated: (state) => {
        state.currentStep = checkStep(state, state.currentStep - 1);
    },
    stepUpdated(state, action: PayloadAction<number>) {
        state.currentStep = checkStep(state, action.payload);
    },
    maxStepUpdated(state, action: PayloadAction<number>) {
        state.maxStep = action.payload;
    }
  },
});

// Export the actions
export const { nextStepUpdated, previousStepUpdated, stepUpdated, maxStepUpdated } = stepperSlice.actions;

export default stepperSlice.reducer;

import { QrCodeContent } from "features/qrcode/type";
import {
  encodedQrContentUpdated,
  qrContentUpdated,
  rawQrContentUpdated,
  forceUpdateStateUpdated,
} from "features/qrcode/qrcode-slice";
import LZString from "lz-string";

export const generateQrCodeContent = (formData: any, qrCodeContentConfig: any, encoding?: string, print?: boolean) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      let qrCodeContent: QrCodeContent = {};
      // Add the config properties to the QR code content
      qrCodeContent = qrCodeContentConfig;
      // Add the form data to the QR code content
      qrCodeContent = {...qrCodeContent, payload: formData};
      // Set the QR code content
      dispatch(qrContentUpdated(qrCodeContent));
      dispatch(rawQrContentUpdated(formData));

      // Encode the QR code content
      let encodedString = "";
      switch (encoding) {
        case "base64":
          encodedString = window.btoa(JSON.stringify(qrCodeContent));
          break;
        case "lz7uri":
          encodedString = LZString.compressToBase64(
            JSON.stringify(qrCodeContent)
          )
            .replace(/\+/g, "-")
            .replace(/\//g, "_");
          break;
        default:
          encodedString = LZString.compressToBase64(
            JSON.stringify(qrCodeContent)
          )
            .replace(/\+/g, "-")
            .replace(/\//g, "_");
          break;
      }
      dispatch(encodedQrContentUpdated(encodedString));
      if (print) {
        dispatch(forceUpdateStateUpdated(true));
      }
    } catch (error: any) {
      console.error(error.message);
    }
  };
};

import { useAppSelector } from "app/hooks";
import QRCode from "easyqrcodejs";
import { useEffect, useRef, useState } from "react";

function QrCodeGenerator() {
  const qrContent = useAppSelector((state) => state.qrcode.encodedQrContent);
  const qrOptions = useAppSelector((state) => state.qrcode.qrConfig);
  const qrCodeDomRef = useRef(null);
  const [qrcode, setqrcode] = useState<QRCode>();
  useEffect(() => {
    let finalQrOptions;
    // Make the same options as qrOptions but handling the correct level
    if (qrOptions) {
      finalQrOptions = {
        ...qrOptions,
        correctLevel: (QRCode.CorrectLevel as any)[qrOptions.correctLevel],
      };
      setqrcode(
        new QRCode(qrCodeDomRef.current, finalQrOptions)
      );
    }
  }, [qrOptions]);

  useEffect(() => {
    if (qrcode) qrcode.makeCode(qrContent);
  }, [qrContent, qrcode]);
  
  
  return (
    <>
      <div ref={qrCodeDomRef} id="qrcode"></div>
    </>
  );
}

export default QrCodeGenerator;

import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "app/theme";
import Home from "pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css"; // Import datepicker styles
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import NavBar from "components/NavBar";
import { getConfig } from "actions/config";
import Alert from "components/ErrorBar/Alert";
import WaterMark from "components/WaterMark";

function App() {
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.config.themes);
  const error = useAppSelector((state) => state.error.error);

  // Get theme from URL params
  const urlParams = new URLSearchParams(window.location.search);
  const themeMode = urlParams.get("theme");

  useEffect(() => {
    dispatch(getConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {theme.lightTheme ? (
        <ThemeProvider
          theme={themeMode === "dark" ? theme.darkTheme : theme.lightTheme}
        >
          <div className="App">
            <Alert></Alert>
            <NavBar theme={themeMode ? themeMode : ""} />
            <GlobalStyle />
            <Home />
            <WaterMark />
          </div>
        </ThemeProvider>
      ) : (
        <div
          className="d-flex align-items-center"
          style={{
            height: "100vh",
            width: "100vw",
          }}
        >
          <h1 className="text-center">
            {error}
          </h1>
        </div>
      )}
    </>
  );
}

export default App;

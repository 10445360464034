import { firstLetterToUpperCase } from "app/common";
import { useAppSelector } from "app/hooks";
import QrCodeGenerator from "components/QrCodeGenerator";
import React from "react";
import styled from "styled-components";
const StyledTicket = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 100%;
`;

function Ticket() {
  const qrCodeContent = useAppSelector((state) => state.qrcode.qrContent);
  const uiConfig = useAppSelector((state) => state.config.ui);
  const formConfig = useAppSelector((state) => state.config.form);

  return (
    <>
      <StyledTicket className="mb-3">
        <img
          src={uiConfig.company ? uiConfig.company.companyLogo : "logo192.png"}
          width={192}
          alt="company-ticket-logo"
        ></img>
        <QrCodeGenerator />
        {/* Loop trough the formConfig groups.inputs to get the label and loop through the qrCodeContent to get the value.
            If the key matches, then return the label and value. */}
        {formConfig.groups.map((group) => {
          return group.inputs.map((input) => {
            // If the input is not set to print, then return null.
            if (!input.print) return null;
            return (
              <React.Fragment key={input.id}>
                {qrCodeContent.payload
                  ? Object.entries(qrCodeContent.payload.form).map(
                      ([key, value]) => {
                        // If the input is not from this group, then return null.
                        if (!input.id.includes(key)) return null;
                        if (key === "context") return null;
                        if (key === input.id) {
                          return (
                            <p key={key}>
                              <b>{firstLetterToUpperCase(input.label)}:</b> {value}
                            </p>
                          );
                        }
                        return null;
                      }
                    )
                  : null}
              </React.Fragment>
            );
          });
        })}
        {/* {listData()} */}.
      </StyledTicket>
    </>
  );
}

export default Ticket;

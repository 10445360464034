import {
  formConfigUpdated,
  themesConfigUpdated,
  uiConfigUpdated,
  virtualKeyboardsConfigUpdated,
} from "features/config/config-slice";
import axios from "axios";
import { maxStepUpdated } from "features/stepper/stepper-slice";
import {
  qrConfigUpdated,
  qrContentConfigUpdated,
} from "features/qrcode/qrcode-slice";
import { errorUpdated } from "features/err/err-slice";
import Ajv from "ajv";
import { configJsonSchema } from "app/jsonschemas";

const ajv = new Ajv();
export const getConfig = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      // Check if there is a "c" parameter in the URL
      const urlParams = new URLSearchParams(window.location.search);
      const c = urlParams.get("c");

      let { data } = {} as any;

      if (c) {
        // Fetch data using the provided URL
        const response = await axios(
          `http://autofill.api.ekami.ch/kiosk/config/${c}.json`
        ).catch((error) => {
          dispatch(
            errorUpdated(
              `Error Getting the online config : ${error.message}, using the local config instead.`
            )
          );
        });

        if (response && response.data) {
          data = response.data;
        }
      }

      // If data is empty or there was an error during the fetch, fetch the default config
      if (!data) {
        const response = await axios(`config.json`).catch((error) => {
          throw new Error(`Error Getting the local config : ${error.message}. Make sure the config file exists.`);
        });

        if (response && response.data) {
          data = response.data;
        }
      }

      if(!ajv.validate(configJsonSchema, data)) {
        throw new Error(`Error validating the config file : ${ajv.errorsText()}. Make sure the config file is valid.`);
      }

      dispatch(formConfigUpdated(data.form));
      dispatch(maxStepUpdated(data.form.groups.length)); // We do not need to remove 1 because we want an extra step for the summary
      dispatch(qrContentConfigUpdated(data.qrCode.content));
      dispatch(qrConfigUpdated(data.qrCode.options));
      dispatch(virtualKeyboardsConfigUpdated(data.keyboards));
      dispatch(uiConfigUpdated(data.ui));
      dispatch(themesConfigUpdated(data.themes));
    } catch (error: any) {
      dispatch(errorUpdated(error.message));
    }
  };
};

import { configureStore } from '@reduxjs/toolkit';
import configSlice from 'features/config/config-slice';
import errorSlice from 'features/err/err-slice';
import qrcodeSlice from 'features/qrcode/qrcode-slice';
import stepperSlice from 'features/stepper/stepper-slice';

import thunk from 'redux-thunk';

export const store = configureStore({
    reducer: {
        config: configSlice,
        stepper: stepperSlice,
        qrcode: qrcodeSlice,
        error: errorSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

// This is the type returned by `configureStore`
export type AppDispatch = typeof store.dispatch;

// This is the type of the `store` itself
export type RootState = ReturnType<typeof store.getState>;

// Path: src/app/store.ts
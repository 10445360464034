import { useEffect, useState } from "react";

function WaterMark() {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  useEffect(() => {
    // If url param theme=dark, then set isDarkTheme to true
    const urlParams = new URLSearchParams(window.location.search);
    const themeMode = urlParams.get("theme");
    if (themeMode === "dark") {
      setIsDarkTheme(true);
    }
  }, []);
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1999,
          display: "flex",
        }}
      >
        <p
          style={{
            alignSelf: "center",
            // Justify text to the middle
            textAlign: "center",
            margin: "0 3px 0 0",
            padding: 0,
          }}
        >
          Made in Switzerland by
        </p>
        <img
          style={{
            alignSelf: "center",
          }}
          src={
            isDarkTheme
              ? "ekamiHorizontalLogoDark.svg"
              : "ekamiHorizontalLogo.svg"
          }
          alt="Ekami Logo"
          height={40}
        ></img>
      </div>
    </>
  );
}

export default WaterMark;

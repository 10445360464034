export const configJsonSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  title: "Generated schema for Root",
  type: "object",
  properties: {
    form: {
      type: "object",
      properties: {
        id: {
          type: "string",
        },
        name: {
          type: "string",
        },
        description: {
          type: "string",
        },
        context: {
          type: "string",
        },
        initialStep: {
          type: "number",
        },
        className: {
          type: "string",
        },
        style: {
          type: "object",
          properties: {},
          required: [],
        },
        groups: {
          type: "array",
          items: {
            type: "object",
            properties: {
              name: {
                type: "string",
              },
              step: {
                type: "number",
              },
              description: {
                type: "string",
              },
              className: {
                type: "string",
              },
              style: {
                type: "object",
                properties: {},
                required: [],
              },
              inputs: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: {
                      type: "string",
                    },
                    label: {
                      type: "string",
                    },
                    type: {
                      type: "string",
                    },
                    placeholder: {
                      type: "string",
                    },
                    disabled: {
                      type: "boolean",
                    },
                    className: {
                      type: "string",
                    },
                    style: {
                      type: "object",
                      properties: {},
                      required: [],
                    },
                    required: {
                      type: "boolean",
                    },
                    "date-picker-options": {
                      type: "object",
                      properties: {
                        format: {
                          type: "string",
                        },
                        startView: {
                          type: "number",
                        },
                        maxViewMode: {
                          type: "number",
                        },
                        language: {
                          type: "string",
                        },
                        keyboardNavigation: {
                          type: "boolean",
                        },
                        autoclose: {
                          type: "boolean",
                        },
                        defaultViewDate: {
                          type: "object",
                          properties: {
                            year: {
                              type: "number",
                            },
                            month: {
                              type: "number",
                            },
                            day: {
                              type: "number",
                            },
                          },
                          required: ["year", "month", "day"],
                        },
                        orientation: {
                          type: "string",
                        },
                      },
                      required: [
                        "format",
                        "startView",
                        "maxViewMode",
                        "language",
                        "keyboardNavigation",
                        "autoclose",
                        "defaultViewDate",
                        "orientation",
                      ],
                    },
                    resettable: {
                      type: "boolean",
                    },
                    defaultValue: {
                      type: "string",
                    },
                    options: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          value: {
                            type: "string",
                          },
                          label: {
                            type: "string",
                          },
                        },
                        required: ["value", "label"],
                      },
                    },
                    "data-kioskboard-type": {
                      type: "string",
                    },
                    "data-kioskboard-placement": {
                      type: "string",
                    },
                    "data-kioskboard-specialcharacters": {
                      type: "string",
                    },
                  },
                  required: [
                    "id",
                    "label",
                    "type",
                    "className",
                    "required",
                  ],
                },
              },
            },
            required: [
              "name",
              "step",
              "className",
              "inputs",
            ],
          },
        },
      },
      required: [
        "id",
        "name",
        "context",
        "initialStep",
        "className",
        "groups",
      ],
    },
    qrCode: {
      type: "object",
      properties: {
        content: {
          type: "object",
          properties: {
            v: {
              type: "string",
            },
            type: {
              type: "string",
            },
          },
          required: ["v", "type"],
        },
        options: {
          type: "object",
          properties: {
            text: {
              type: "string",
            },
            width: {
              type: "number",
            },
            height: {
              type: "number",
            },
            colorDark: {
              type: "string",
            },
            colorLight: {
              type: "string",
            },
            correctLevel: {
              type: "string",
            },
            dotScale: {
              type: "number",
            },
            quietZone: {
              type: "number",
            },
            quietZoneColor: {
              type: "string",
            },
            logoBackgroundTransparent: {
              type: "boolean",
            },
            autoColor: {
              type: "boolean",
            },
            autoColorDark: {
              type: "string",
            },
            autoColorLight: {
              type: "string",
            },
          },
          required: [
            "text",
            "width",
            "height",
            "colorDark",
            "colorLight",
            "correctLevel",
            "dotScale",
            "quietZone",
            "quietZoneColor",
            "logoBackgroundTransparent",
            "autoColor",
            "autoColorDark",
            "autoColorLight",
          ],
        },
      },
      required: ["content", "options"],
    },
    keyboards: {
      type: "object",
      properties: {
        postalCodeKeyboard: {
          type: "object",
          properties: {
            target: {
              type: "string",
            },
            options: {
              type: "object",
              properties: {
                theme: {
                  type: "string",
                },
                keysArrayOfObjects: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      "0": {
                        type: "string",
                      },
                      "1": {
                        type: "string",
                      },
                      "2": {
                        type: "string",
                      },
                      "3": {
                        type: "string",
                      },
                      "4": {
                        type: "string",
                      },
                      "5": {
                        type: "string",
                      },
                      "6": {
                        type: "string",
                      },
                      "7": {
                        type: "string",
                      },
                      "8": {
                        type: "string",
                      },
                      "9": {
                        type: "string",
                      },
                    },
                    required: ["0", "1", "2", "3", "4", "5", "6"],
                  },
                },
                cssAnimationsStyle: {
                  type: "string",
                },
                autoScroll: {
                  type: "boolean",
                },
                cssAnimations: {
                  type: "boolean",
                },
                capsLockActive: {
                  type: "boolean",
                },
                allowRealKeyboard: {
                  type: "boolean",
                },
                allowMobileKeyboard: {
                  type: "boolean",
                },
              },
              required: [
                "theme",
                "keysArrayOfObjects",
                "cssAnimationsStyle",
                "autoScroll",
                "cssAnimations",
                "capsLockActive",
                "allowRealKeyboard",
                "allowMobileKeyboard",
              ],
            },
          },
          required: ["target", "options"],
        },
        generalKeyboard: {
          type: "object",
          properties: {
            target: {
              type: "string",
            },
            options: {
              type: "object",
              properties: {
                theme: {
                  type: "string",
                },
                keysArrayOfObjects: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      "0": {
                        type: "string",
                      },
                      "1": {
                        type: "string",
                      },
                      "2": {
                        type: "string",
                      },
                      "3": {
                        type: "string",
                      },
                      "4": {
                        type: "string",
                      },
                      "5": {
                        type: "string",
                      },
                      "6": {
                        type: "string",
                      },
                      "7": {
                        type: "string",
                      },
                      "8": {
                        type: "string",
                      },
                      "9": {
                        type: "string",
                      },
                    },
                    required: ["0", "1", "2", "3", "4", "5", "6"],
                  },
                },
                cssAnimationsStyle: {
                  type: "string",
                },
                autoScroll: {
                  type: "boolean",
                },
                cssAnimations: {
                  type: "boolean",
                },
                capsLockActive: {
                  type: "boolean",
                },
                allowRealKeyboard: {
                  type: "boolean",
                },
                allowMobileKeyboard: {
                  type: "boolean",
                },
              },
              required: [
                "theme",
                "keysArrayOfObjects",
                "cssAnimationsStyle",
                "autoScroll",
                "cssAnimations",
                "capsLockActive",
                "allowRealKeyboard",
                "allowMobileKeyboard",
              ],
            },
          },
          required: ["target", "options"],
        },
        mailKeyboard: {
          type: "object",
          properties: {
            target: {
              type: "string",
            },
            options: {
              type: "object",
              properties: {
                theme: {
                  type: "string",
                },
                keysArrayOfObjects: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      "0": {
                        type: "string",
                      },
                      "1": {
                        type: "string",
                      },
                      "2": {
                        type: "string",
                      },
                      "3": {
                        type: "string",
                      },
                      "4": {
                        type: "string",
                      },
                      "5": {
                        type: "string",
                      },
                      "6": {
                        type: "string",
                      },
                      "7": {
                        type: "string",
                      },
                      "8": {
                        type: "string",
                      },
                      "9": {
                        type: "string",
                      },
                      "10": {
                        type: "string",
                      },
                    },
                    required: [
                      "0",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                    ],
                  },
                },
                cssAnimationsStyle: {
                  type: "string",
                },
                autoScroll: {
                  type: "boolean",
                },
                cssAnimations: {
                  type: "boolean",
                },
                capsLockActive: {
                  type: "boolean",
                },
                allowRealKeyboard: {
                  type: "boolean",
                },
                allowMobileKeyboard: {
                  type: "boolean",
                },
              },
              required: [
                "theme",
                "keysArrayOfObjects",
                "cssAnimationsStyle",
                "autoScroll",
                "cssAnimations",
                "capsLockActive",
                "allowRealKeyboard",
                "allowMobileKeyboard",
              ],
            },
          },
          required: ["target", "options"],
        },
      },
    },
    themes: {
      type: "object",
      properties: {
        darkTheme: {
          type: "object",
          properties: {
            colors: {
              type: "object",
              properties: {
                base: {
                  type: "string",
                },
                mantle: {
                  type: "string",
                },
                crust: {
                  type: "string",
                },
                text: {
                  type: "string",
                },
                subtext0: {
                  type: "string",
                },
                subtext1: {
                  type: "string",
                },
                surface0: {
                  type: "string",
                },
                surface1: {
                  type: "string",
                },
                surface2: {
                  type: "string",
                },
                overlay0: {
                  type: "string",
                },
                overlay1: {
                  type: "string",
                },
                overlay2: {
                  type: "string",
                },
                blue: {
                  type: "string",
                },
                lavender: {
                  type: "string",
                },
                sapphire: {
                  type: "string",
                },
                sky: {
                  type: "string",
                },
                teal: {
                  type: "string",
                },
                green: {
                  type: "string",
                },
                yellow: {
                  type: "string",
                },
                peach: {
                  type: "string",
                },
                maroon: {
                  type: "string",
                },
                red: {
                  type: "string",
                },
                mauve: {
                  type: "string",
                },
                pink: {
                  type: "string",
                },
                flamingo: {
                  type: "string",
                },
                rosewater: {
                  type: "string",
                },
              },
              required: [
                "base",
                "mantle",
                "crust",
                "text",
                "subtext0",
                "subtext1",
                "surface0",
                "surface1",
                "surface2",
                "overlay0",
                "overlay1",
                "overlay2",
                "blue",
                "lavender",
                "sapphire",
                "sky",
                "teal",
                "green",
                "yellow",
                "peach",
                "maroon",
                "red",
                "mauve",
                "pink",
                "flamingo",
                "rosewater",
              ],
            },
            filters: {
              type: "object",
              properties: {
                brightness: {
                  type: "string",
                },
                invert: {
                  type: "string",
                },
              },
              required: ["brightness", "invert"],
            },
          },
          required: ["colors", "filters"],
        },
        lightTheme: {
          type: "object",
          properties: {
            colors: {
              type: "object",
              properties: {
                base: {
                  type: "string",
                },
                mantle: {
                  type: "string",
                },
                crust: {
                  type: "string",
                },
                text: {
                  type: "string",
                },
                subtext0: {
                  type: "string",
                },
                subtext1: {
                  type: "string",
                },
                surface0: {
                  type: "string",
                },
                surface1: {
                  type: "string",
                },
                surface2: {
                  type: "string",
                },
                overlay0: {
                  type: "string",
                },
                overlay1: {
                  type: "string",
                },
                overlay2: {
                  type: "string",
                },
                blue: {
                  type: "string",
                },
                lavender: {
                  type: "string",
                },
                sapphire: {
                  type: "string",
                },
                sky: {
                  type: "string",
                },
                teal: {
                  type: "string",
                },
                green: {
                  type: "string",
                },
                yellow: {
                  type: "string",
                },
                peach: {
                  type: "string",
                },
                maroon: {
                  type: "string",
                },
                red: {
                  type: "string",
                },
                mauve: {
                  type: "string",
                },
                pink: {
                  type: "string",
                },
                flamingo: {
                  type: "string",
                },
                rosewater: {
                  type: "string",
                },
              },
              required: [
                "base",
                "mantle",
                "crust",
                "text",
                "subtext0",
                "subtext1",
                "surface0",
                "surface1",
                "surface2",
                "overlay0",
                "overlay1",
                "overlay2",
                "blue",
                "lavender",
                "sapphire",
                "sky",
                "teal",
                "green",
                "yellow",
                "peach",
                "maroon",
                "red",
                "mauve",
                "pink",
                "flamingo",
                "rosewater",
              ],
            },
            filters: {
              type: "object",
              properties: {
                brightness: {
                  type: "string",
                },
                invert: {
                  type: "string",
                },
              },
              required: ["brightness", "invert"],
            },
          },
          required: ["colors", "filters"],
        },
      },
      required: ["darkTheme", "lightTheme"],
    },
    ui: {
      type: "object",
      properties: {
        company: {
          type: "object",
          properties: {
            companyLogo: {
              type: "string",
            },
            companyLogoDark: {
              type: "string",
            },
            companyName: {
              type: "string",
            },
          },
          required: ["companyLogo", "companyLogoDark", "companyName"],
        },
        buttons: {
          type: "object",
          properties: {
            print: {
              type: "string",
            },
            user: {
              type: "string",
            },
          },
          required: ["print", "user"],
        },
        messages: {
          type: "object",
          properties: {
            thanks: {
              type: "string",
            },
          },
          required: ["thanks"],
        },
      },
      required: ["company", "buttons", "messages"],
    },
  },
  required: ["form", "qrCode", "keyboards", "themes", "ui"],
};
